<template>
  <div>
    <b-card>
      <p
        @click="instanceCard = !instanceCard"
        block
        v-b-toggle.accordion-1
        variant="light"
        class="col-12"
      >
        <b-row class="justify-content-between">
          <b-col md="auto">
            <b-button @click="newDevis">
              <feather-icon icon="FilePlusIcon" size="15" /> Nouveau Devis
            </b-button>
          </b-col>
          <b-col md="auto">
            <b-button
              :to="{ name: 'devis-corbeille' }"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" size="15" />
              Corbeille
            </b-button>
          </b-col>
        </b-row>
      </p>
      <hr />

      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Filtrer par :"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="4"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                <option :value="false">Croissant</option>
                <option :value="true">Décroissant</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Recherche..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Effacer
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        :busy="isBusy"
        style="height: 57vh"
        empty-text="Aucun devis disponible"
        empty-filtered-text="Aucun devis trouvé"
        show-empty
        head-variant="dark"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        class="generated-card position-relative"
        sticky-header="5"
        striped
        :items="devis"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Chargement...</strong>
          </div>
        </template>
        <template #cell(customer)="data">
          {{ data.item.customer.name }}
        </template>
        <template #cell(is_validate)="data">
          <small class="text-warning" v-if="data.item.is_validate == 0">
            <b-badge pill variant="warning">!</b-badge> En attente de validation
          </small>
          <small class="text-success" v-if="data.item.is_validate == 1">
            <b-badge pill variant="success">!</b-badge> Devis Validé
          </small>
          <small class="text-danger" v-if="data.item.is_validate == 2">
            <b-badge pill variant="danger">!</b-badge> Devis Rejeté
          </small>
        </template>
        <template #cell(total)="data">
          <span class="p-1">{{ totalTTC(data.item), }}</span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>

            <b-dropdown-item target="_blank" :href="data.item.filename_devis">
              <feather-icon
                icon="EyeIcon"
                size="18"
                style="margin-right: 5px"
              />
              Voir le devis
            </b-dropdown-item>
            <b-dropdown-item :to="'/edit/task/devis/' + data.item.id">
              <feather-icon
                icon="EditIcon"
                size="18"
                style="margin-right: 5px"
              />
              Éditer le devis
            </b-dropdown-item>
            <b-dropdown-item
              button
              v-if="data.item.is_validate == 0"
              @click="
                getDevis(data.item), (showSendingModal = !showSendingModal)
              "
            >
              <feather-icon
                icon="SendIcon"
                size="18"
                style="margin-right: 5px"
              />
              Envoyer au client
            </b-dropdown-item>
            <b-dropdown-item
              button
              v-if="data.item.is_validate == 0"
              @click="getDevis(data.item), (showValidatingModal = true)"
            >
              <feather-icon
                icon="CheckIcon"
                size="18"
                style="margin-right: 5px"
              />
              Valider devis
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.is_validate == 0"
              @click="doCopy(data.item.devis_num_enterprise_id)"
            >
              <feather-icon
                icon="CopyIcon"
                size="18"
                style="margin-right: 5px"
              />
              Copier le lien pour validation
            </b-dropdown-item>
            <b-dropdown-item button @click="shareInvoiceShowModal(data.item)">
              <feather-icon
                icon="ShareIcon"
                size="18"
                style="margin-right: 5px"
              />
              Partager le devis
            </b-dropdown-item>
            <b-dropdown-item @click="cancelDevis(data.item)">
              <feather-icon
                icon="TrashIcon"
                size="18"
                style="margin-right: 5px"
              />
              Placer dans la corbeille
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(date)="data">
          {{ data.item.created_at }}
        </template>
      </b-table>
      <!-- SHARE INVOICE STARTING MODAAL -->
      <b-modal
        size="sm"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        v-model="shareDevisModal"
        id="share-modal-center"
        centered
        :title="'Partager le Devis N° : ' + thisDevis.devis_num"
      >
        <b-overlay :show="loading" rounded="sm">
          <p>Avec :</p>
          <validation-observer ref="shareDevisForm">
            <b-form>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  name="E-mail"
                >
                  <label for=""
                    >E-mail <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    placeholder="E-mail"
                    v-model="share.email"
                  ></b-form-input>
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider>
                  <label for="">E-mail en copie </label>
                  <b-form-input
                    placeholder="E-mail en copie"
                    v-model="share.cc"
                  ></b-form-input>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider>
                  <label for="">Sujet </label>
                  <b-form-input
                    placeholder="Sujet"
                    v-model="share.subject"
                  ></b-form-input>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  :rules="'required'"
                  #default="{ errors }"
                  name="Message"
                >
                  <b-textarea
                    placeholder="message..."
                    v-model="share.message"
                  ></b-textarea>
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <div class="text-center">
            <b-button
              :disabled="sharing"
              size="sm"
              @click="shareDevisFunction(invoice)"
            >
              <b-spinner v-if="sharing" small type="grow"></b-spinner>
              Partager</b-button
            >
            <b-button
              class="ml-1"
              variant="outline-danger"
              size="sm"
              @click="(invoice = {}), (shareDevisModal = false)"
              >Fermer</b-button
            >
          </div>
        </b-overlay>
      </b-modal>
      <!-- SHARING INVOICE MODEL END -->
      <b-modal
        centered
        size="sm"
        hide-footer
        no-close-on-backdrop
        v-model="showSendingModal"
        id="modal-center"
        :title="'Envoi du Devis N° : ' + thisDevis.devis_num"
      >
        <p>Voulez-vous vraiment envoyer ce devis au client ?</p>
        <hr />
        <div class="text-center">
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-primary"
            @click="showSendingModal = false"
            >Non, annuler</b-button
          >
          <b-button
            :disabled="sendIsProgress"
            size="sm"
            @click="sendInvoice(thisDevis)"
          >
            <b-spinner v-if="sendIsProgress" small />
            <feather-icon v-else icon="SendIcon" /> Oui, envoyer
          </b-button>
        </div>
      </b-modal>
      <b-modal
        centered
        size="sm"
        hide-footer
        no-close-on-backdrop
        v-model="showValidatingModal"
        id="modal-center"
        :title="'Validation du Devis N° : ' + thisDevis.devis_num"
      >
        <p>
          Voulez-vous vraiment valider ce devis ? <br />
          Cette action doit être effectuée par le client
        </p>
        <hr />
        <div class="text-center">
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-primary"
            @click="showValidatingModal = false"
            >Non, annuler</b-button
          >
          <b-button
            :disabled="validating"
            size="sm"
            @click="validateThisDevis(thisDevis)"
          >
            <b-spinner v-if="validating" small />
            <feather-icon v-else icon="CheckIcon" /> Oui, valider
          </b-button>
        </div>
      </b-modal>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BToast } from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapState, mapActions } from "vuex";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import moment from "moment";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BToast,
    ToastificationContent,
    FeatherIcon,
    KyInput,
  },
  setup() {
    return {
      validateData: {
        email: JSON.parse(localStorage.getItem("userInfo")).email,
        devis_num: "",
      },
      shareDevisModal: false,
      sharing: false,
      loading: false,
      showSendingModal: false,
      share: {
        invoice_id: null,
        email: "",
        cc: "",
        subject: "Partage de Devis",
        message: "",
      },
      shareDefault: {
        invoice_id: null,
        email: "",
        cc: "",
        subject: "Partage de Devis",
        message: "",
      },
      isBusy: false,
      sendIsProgress: false,
      validating: false,
      thisDevis: {},
      invoice: null,
      showValidatingModal: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "date",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      instanceCard: false,
      fields: [
        // { key: "id", label: "#", sortable: true },
        { key: "devis_num", label: "Numéro", sortable: true },
        { key: "customer", label: "Client", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "is_validate", label: "Etat", sortable: true },
        { key: "totalTTC", label: "Total TTC(€)", sortable: true },
        { key: "actions", thStyle: { width: "5%" } },
      ],
    };
  },
  computed: {
    ...mapState("tasks", ["devis", "invoicesAndInvoiceDeteled"]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.isBusy = true;
    this.allDevis().then(() => {
      this.isBusy = false;
    });
    // Set the initial number of items
    this.totalRows = this.devis.length;
  },

  watch: {
    devis(val) {
      this.totalRows = val.length;
    },
  },

  methods: {
    ...mapActions("tasks", [
      "allDevis",
      "sendInvoiceToCustomer",
      "removeThisDevis",
      "devisValidate",
      "shareDevisToAnyOne",
    ]),

    shareInvoiceShowModal(thisDevis) {
      this.thisDevis = thisDevis;
      this.shareDevisModal = true;
    },

    async shareDevisFunction() {
      const isValid = await this.$refs.shareDevisForm.validate();
      if (!isValid) return;
      this.sharing = true;
      this.share.invoice_id = this.thisDevis.id;
      this.shareDevisToAnyOne(this.$formData(this.share))
        .then((res) => {
          this.shareDevisModal = false;
          this.share = this.shareDefault;
          this.$nextTick().then((response) => {
            this.$refs.shareDevisForm.reset();
          });
          this.sharing = false;
        })
        .catch((err) => {
          this.sharing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },

    totalTTC(data) {
      var total = data.total;
      var discount = total - (total * data.discount) / 100;
      var tva = (discount * data.tva) / 100;
      var tttc = total - discount + tva;
      if (Number.isInteger(total) == true) {
        return tttc;
      } else {
        return tttc.toFixed(2);
      }
    },

    newDevis() {
      this.$router.push({ name: "task-devis" });
      let last_devis = this.devis[this.devis.length - 1];
      localStorage.setItem("lastDevis", JSON.stringify(last_devis));
    },

    cancelDevis(data) {
      this.removeThisDevis(data.devis_num_enterprise_id).then((e) => {
        this.allDevis();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Devis placé dans la corbeille",
            icon: "BellIcon",
            variant: "success",
          },
        });
      });
    },

    doCopy(data) {
      this.$copyText("https://ekoworking.com/invoice/validate/" + data).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Lien copié avec succès",
              icon: "BellIcon",
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur!",
              icon: "BellIcon",
            },
          });
        }
      );
    },

    getDevis(data) {
      this.thisDevis = data;
    },

    sendInvoice(data) {
      this.sendIsProgress = true;
      this.sendInvoiceToCustomer(data.id)
        .then((e) => {
          this.showSendingModal = false;
          this.sendIsProgress = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Devis envoyé avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oups😢! Une erreur s'est produite",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    validateThisDevis(data) {
      this.validating = true;
      this.validateData.devis_num = data.devis_num_enterprise_id;
      this.devisValidate(this.validateData)
        .then((e) => {
          this.showValidatingModal = false;
          this.validating = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Devis validé avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.validating = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur de validation",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.generated-card {
  max-height: 70vh;
}
</style>